<template>
  <ion-grid>
    <ion-radio-group
      :value="currentPage === '/sale/select-customer' ? customerSelect?.id || null : null"
      mode="md"
    >
      <ion-row class="ion-align-items-start" @click="tapCustomerCard">
        <ion-col size="auto">
          <div class="image-default" :style="`background: ${theme.background}; color:${theme.color}`">
            {{ getInitialName(item.name) }}
          </div>
        </ion-col>
        <ion-col>
          <ion-row>
            <span class="label-card">{{ item.name }}</span>
          </ion-row>
          <ion-row>
            <span class="text-primary my-1">{{ item.account_number }}</span>
          </ion-row>
          <ion-row>
            <ion-label>{{ item.address || 'Addresses not provided' }}</ion-label>
          </ion-row>
          <ion-row class="ion-align-items-center mt-1" v-if="item.black_list">
            <div class="box-label d-flex align-center">
              <ion-icon size="small" :icon="banOutline" color="danger"></ion-icon>
              <span class="text-danger ml-1 fs-2 fw-500">{{ $t('black_listed') }}</span>
            </div>
          </ion-row>
        </ion-col>
        <ion-col v-if="currentPage === '/sale/select-customer'" size="auto">
          <ion-radio :value="item.id"></ion-radio>
        </ion-col>
      </ion-row>
      <div class="form-btn" v-if="isPendingReview">
        <ion-button mode="md" fill="outline" size="small" @click="contactUs">
          <span style="text-transform: capitalize">{{ $t('contact_us') }}</span>
          <img :src="wa" style="margin-left: 5px" />
        </ion-button>
      </div>
      <div class="form-btn" v-if="isPendingRequest">
        <ion-button mode="md" class="btn" fill="outline" size="small">
          <span style="text-transform: capitalize">{{ $t('reject') }}</span>
        </ion-button>
        <ion-button mode="md" class="btn" size="small">
          <span style="text-transform: capitalize">{{ $t('accept') }}</span>
        </ion-button>
      </div>
    </ion-radio-group>
  </ion-grid>
</template>
<script>
import wa from '@/assets/icons/shared/wa.svg';
import { getAddressFormatWithStall, handleRamdomBackground } from '@/modules/b2b/services/libs/helper';
import { banOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CardCompany',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    currentPage: {
      type: String,
      default: ''
    },
    customerSelect: {
      type: Object,
      default: () => ({})
    },
    activeTab: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    isPendingRequest: {
      type: Boolean,
      default: false
    },
    isPendingReview: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openModal'],
  setup() {
    return {
      getAddressFormatWithStall,
      wa,
      banOutline
    };
  },
  data: () => ({
    isDefaultImage: false,
    theme: []
  }),
  async created() {
    if (!this.imageUrl) {
      this.isDefaultImage = true;
      this.theme = handleRamdomBackground();
    }
  },
  methods: {
    contactUs() {
      window.open('https://wa.me/6588353386', '_blank');
    },
    tapCustomerCard() {
      if (this.currentPage === '/sale/select-customer') {
        this.$emit('openModal');
      } else {
        this.goToDetail();
      }
    },
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    async goToDetail() {
      if (this.activeTab === 'myCustomer') {
        this.$router.push(`/sale/customers/${this.item.id}?tagged=true`);
      } else {
        this.$router.push(`/sale/customers/${this.item.id}`);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.label-card {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 3px;
}
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}

.form-btn {
  display: flex;
  justify-content: flex-end;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 7px;
}
</style>
